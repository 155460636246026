var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-table-wrap"},[(_vm.isMetricsDisplayed)?[(this.$route.name === 'keywordList')?[(_vm.isMobileView)?_c('div',{staticClass:"chart-and-metrics-wrapper"},[_c('single-project-metrics',{attrs:{"loading":_vm.getMetricsIsFetching,"project":_vm.getMetricsData,"fullWidth":!_vm.isChartDisplayed},on:{"onDateChange":_vm.onDateChange}}),_vm._v(" "),(_vm.isChartDisplayed)?_c('div',{directives:[{name:"resize",rawName:"v-resize",value:(function () { return _vm.debouncedHandler(_vm.resizeChart); }),expression:"() => debouncedHandler(resizeChart)"}],staticClass:"overview-chart-container"},[_c('p',{staticClass:"project-overview__chart-header"},[_vm._v("\n                        "+_vm._s(_vm.$t('keywords-chart-header'))+"\n                    ")]),_vm._v(" "),_c('keywords-in-top-chart',{key:("keywords_in_top_" + _vm.chartKey),attrs:{"chart":_vm.chart,"project":_vm.projectData,"loading":_vm.getProjectKeywordsInTopPositions.loading,"isChartDisplayed":"","showLatest":""},on:{"setChart":_vm.setChart}})],1):_vm._e()],1):_c('div',[_c('metrics',{attrs:{"data":_vm.getMetricsData,"loading":_vm.getMetricsIsFetching,"type":"chart"}})],1)]:(!_vm.getViewkeyView)?[_c('metrics',{attrs:{"data":_vm.getMetricsData,"loading":_vm.getMetricsIsFetching}})]:_c('chart-and-metrics',{attrs:{"loading":_vm.getMetricsAndChartIsFetching,"overview-chart-options":_vm.overviewChartOptions,"project":_vm.getProjectWithOverviewChartsData,"isViewkey":""}})]:_vm._e(),_vm._v(" "),(_vm.overviewTabIsDisplayed)?_c('tabs',{class:{
            'tab__share-of-voice':
                _vm.getIsMindshareDataCalculationInProgress &&
                _vm.activeTab === _vm.activeTabs.competitions,
            'tab__keyword-suggestions':
                _vm.activeTab === _vm.activeTabs.suggestions,
        },model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('tab',{attrs:{"header":_vm.getTabHeader(_vm.$t('keywords-first-upper'), 'keywords-tab')}},[(_vm.activeTab == _vm.activeTabs.keywords)?_c('project-keywords-and-charts'):_vm._e()],1),_vm._v(" "),_c('tab',{attrs:{"header":_vm.getTabHeader(_vm.$t('top-pages.title'), 'top-pages-tab')}},[(_vm.activeTab == _vm.activeTabs.topPages)?_c('top-pages-tab'):_vm._e()],1),_vm._v(" "),_c('tab',{attrs:{"header":_vm.getTabHeader(_vm.$t('share-of-voice'), 'share-of-voice-tab')}},[(
                    _vm.getCurrentProject &&
                    _vm.activeTab === _vm.activeTabs.competitions
                )?_c('competitors-panel'):_vm._e()],1),_vm._v(" "),(_vm.SHOW_GSC_SERVICE && !this.getViewkeyView)?_c('tab',{attrs:{"header":_vm.getTabHeader(
                    _vm.$t('keyword-suggestions', {
                        count: _vm.getSuggestionsCount,
                    }),
                    'keyword-suggestions-tab'
                )}},[(
                    _vm.getCurrentProject &&
                    _vm.activeTab === _vm.activeTabs.suggestions
                )?_c('keyword-suggestions'):_vm._e()],1):_vm._e()],1):_c('project-keywords-and-charts'),_vm._v(" "),(_vm.editKeywordsPopup.isVisible)?_c('edit-keywords-popup',{attrs:{"editable-keyword":_vm.editKeywordsPopup.editableKeyword,"multiple":_vm.editKeywordsPopup.multiple,"is-all":_vm.editKeywordsPopup.isAll,"updatable-keywords-array":_vm.getEditableItem},on:{"unset-editable-keyword":_vm.unsetEditableKeyword}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }