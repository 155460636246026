<template>
    <main-block v-if="showOldMainBlock" />
    <main-block-with-overview v-else-if="showMainBlock" />
</template>

<script>
    import {mapGetters} from 'vuex';
    import MainBlockWithOverview from '@/components/main-block/MainBlockWithOverview.vue';
    import MainBlock from '@/components/main-block/MainBlock.vue';
    import {oldOverviewUsers} from '@/constants'
    
    export default {
        name: 'ProjectPage',
        components: {
            'main-block-with-overview': MainBlockWithOverview,
            'main-block': MainBlock,
        },
        computed: {
            ...mapGetters([
                'getUserDataProp',
            ]),
            showMainBlock(){
                return !!this.getUserDataProp('email');
            },
            showOldMainBlock(){
                return oldOverviewUsers.includes(this.getUserDataProp('email'));
            }
        },
    }
</script>
