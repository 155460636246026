<template>
    <div class="dashboard-table-wrap">
        <template v-if="isMetricsDisplayed">
            <template v-if="this.$route.name === 'keywordList'">
                <div v-if="isMobileView" class="chart-and-metrics-wrapper">
                    <single-project-metrics
                        :loading="getMetricsIsFetching"
                        :project="getMetricsData"
                        @onDateChange="onDateChange"
                        :fullWidth="!isChartDisplayed"
                    />
                    
                    <div v-if="isChartDisplayed" class="overview-chart-container" v-resize="() => debouncedHandler(resizeChart)">
                        <p class="project-overview__chart-header">
                            {{ $t('keywords-chart-header') }}
                        </p>
                        <keywords-in-top-chart
                            :chart="chart"
                            :project="projectData"
                            :loading="getProjectKeywordsInTopPositions.loading"
                            isChartDisplayed
                            @setChart="setChart"
                            :key="`keywords_in_top_${chartKey}`"
                            showLatest
                        />
                    </div>
                </div>
                <div v-else>
                    <metrics
                        :data="getMetricsData"
                        :loading="getMetricsIsFetching"
                        type="chart"
                    />
                </div>
            </template>
            <template v-else-if="!getViewkeyView">
                <metrics
                    :data="getMetricsData"
                    :loading="getMetricsIsFetching"
                />
            </template>

            <chart-and-metrics
                v-else
                :loading="getMetricsAndChartIsFetching"
                :overview-chart-options="overviewChartOptions"
                :project="getProjectWithOverviewChartsData"
                isViewkey
            />
        </template>
        <tabs
            v-if="overviewTabIsDisplayed"
            v-model="activeTab"
            :class="{
                'tab__share-of-voice':
                    getIsMindshareDataCalculationInProgress &&
                    activeTab === activeTabs.competitions,
                'tab__keyword-suggestions':
                    activeTab === activeTabs.suggestions,
            }"
        >
            <tab
                :header="
                    getTabHeader($t('keywords-first-upper'), 'keywords-tab')
                "
            >
                <project-keywords-and-charts
                    v-if="activeTab == activeTabs.keywords"
                />
            </tab>
            <tab :header="getTabHeader($t('top-pages.title'), 'top-pages-tab')">
                <top-pages-tab v-if="activeTab == activeTabs.topPages" />
            </tab>
            <tab
                :header="
                    getTabHeader($t('share-of-voice'), 'share-of-voice-tab')
                "
            >
                <competitors-panel
                    v-if="
                        getCurrentProject &&
                        activeTab === activeTabs.competitions
                    "
                />
            </tab>
            <tab
                v-if="SHOW_GSC_SERVICE && !this.getViewkeyView"
                :header="
                    getTabHeader(
                        $t('keyword-suggestions', {
                            count: getSuggestionsCount,
                        }),
                        'keyword-suggestions-tab',
                    )
                "
            >
                <keyword-suggestions
                    v-if="
                        getCurrentProject &&
                        activeTab === activeTabs.suggestions
                    "
                />
            </tab>
        </tabs>
        <project-keywords-and-charts v-else />

        <edit-keywords-popup
            v-if="editKeywordsPopup.isVisible"
            :editable-keyword="editKeywordsPopup.editableKeyword"
            :multiple="editKeywordsPopup.multiple"
            :is-all="editKeywordsPopup.isAll"
            :updatable-keywords-array="getEditableItem"
            @unset-editable-keyword="unsetEditableKeyword"
        />
    </div>
</template>

<script>
import { Events, EventBus } from '@/events';
import { mapActions, mapGetters } from 'vuex';
import { overviewChartOptions } from '@/configs/overviewCharts';
import { tabs, tab } from 'vue-strap';
import ChartAndMetrics from 'components/common/ChartAndMetrics';
import CompetitorsPanel from 'components/competitors-panel/CompetitorsPanel';
import KeywordSuggestions from 'components/keyword-suggestions/KeywordSuggestions';
import TopPagesTab from './TopPages/Tab';
import Metrics from 'components/metrics/Metrics';
import ProjectKeywordsAndCharts from './ProjectKeywordsAndCharts';
const EditKeywordsPopup = () =>
    import('components/main-table/EditKeywordsPopup');
const MoveKeywordsModal = () =>
    import('components/main-table/MoveKeywordsModal');
import { processKeywords } from '@/store/utils';
import {
    MOBILE_WIDTH_RESOLUTION_PHONE,
    PROJECT_ACTIVE_TABS,
} from '@/constants';
import SingleProjectMetrics from '../../common/SingleProjectMetrics.vue';
import KeywordsInTopChart from '../../common/KeywordsInTopChart.vue';
import { exactDatePickerList } from '@/components/date-range-picker/datePickerList'
import {switchDateFormat} from '@/helpers/format-date-service';
import resize from 'vue-resize-directive';

export default {
    name: 'Project',
    components: {
        'chart-and-metrics': ChartAndMetrics,
        'competitors-panel': CompetitorsPanel,
        'keyword-suggestions': KeywordSuggestions,
        'top-pages-tab': TopPagesTab,
        'edit-keywords-popup': EditKeywordsPopup,
        metrics: Metrics,
        'project-keywords-and-charts': ProjectKeywordsAndCharts,
        tab: tab,
        tabs: tabs,
        'single-project-metrics': SingleProjectMetrics,
        'keywords-in-top-chart': KeywordsInTopChart
    },
    directives: {
        resize,
    },
    data() {
        return {
            overviewChartOptions,
            editKeywordsPopup: {
                isVisible: false,
                editableKeyword: null,
                multiple: false,
            },
            activeTab: 0,
            activeTabs: PROJECT_ACTIVE_TABS,
            chart: null,
            projectData: {}
        };
    },
    computed: {
        ...mapGetters([
            'getActiveTag',
            'getViewkeyView',
            'getMetricsData',
            'getOtherSetting',
            'getOriginalItems',
            'getGSCSuggestions',
            'getDisplaySetting',
            'getCurrentProject',
            'getPermissionData',
            'getSelectedKeywords',
            'isAdminOrMasterUser',
            'getMetricsIsFetching',
            'getGlobalSearchQuery',
            'getIsAllCheckboxesSelected',
            'getSortedAndFilteredKeywords',
            'getMetricsAndChartIsFetching',
            'getGSCProfileCurrentConnection',
            'getSelectedKeywordsWithAllData',
            'getProjectWithOverviewChartsData',
            'getIsMindshareDataCalculationInProgress',
            'getProjectKeywordsInTopPositions'
        ]),
        SHOW_GSC_SERVICE() {
            return process.env.SHOW_GSC_SERVICE == 'true';
        },
        getSuggestionsCount() {
            return this.getGSCProfileCurrentConnection &&
                this.getGSCSuggestions?.length
                ? `<span style="color: #0074D9;">(${this.getGSCSuggestions.length})</span>`
                : '';
        },
        isSearch() {
            return this.$route.name === 'searchResults';
        },
        isMobileView() {
            return MOBILE_WIDTH_RESOLUTION_PHONE < window.screen.width;
        },
        getEditableItem() {
            const data = this.editKeywordsPopup.multiple
                ? this.getSelectedKeywordsWithAllData
                : this.editKeywordsPopup.isAll
                ? this.getSortedAndFilteredKeywords(this.getOriginalItems)
                : [this.editKeywordsPopup.editableKeyword];
            return data;
        },
        overviewTabIsDisplayed() {
            return (
                (!this.isSearch && !this.getViewkeyView) ||
                (this.getViewkeyView &&
                    this.getCurrentProject?.sov_view_key &&
                    this.getPermissionData('share_of_voice'))
            );
        },
        isMetricsDisplayed() {
            return (
                !this.getGlobalSearchQuery &&
                this.getDisplaySetting('metrics') !== 'hide'
            );
        },
        isChartDisplayed() {
            return this.getOtherSetting('rankingAreaChart') !== 'hide';
        },
        chartKey(){
            return this.getActiveTag?.id || this.getCurrentProject?.id;
        },
    },
    watch: {
        activeTab(tab) {
            if (
                tab === this.activeTabs.keywords ||
                tab === this.activeTabs.topPages
            ) {
                const staticTableHeader = document.querySelector(
                    '.keywords-table_static .keywords-table__header',
                );
                const dynamicTableHeader = document.querySelector(
                    '.keywords-table_dynamic .keywords-table__header',
                );
                if (staticTableHeader && dynamicTableHeader) {
                    dynamicTableHeader.classList.remove(
                        'keywords-table__header_fixed',
                    );
                    staticTableHeader.classList.remove(
                        'keywords-table__header_fixed',
                    );
                    staticTableHeader.style.width = 'unset';
                    dynamicTableHeader.style.width = 'unset';
                }
            }
        },
        getActiveTag() {
            this.setShownChart({ keyword: null, project: null });
            if(this.getCurrentProject){
                this.fetchChartData();
            }
        },
    },
    mounted(){
        if(this.getCurrentProject){
            this.fetchChartData();
        }
    },
    async created() {
        EventBus.on(Events.SET_EDITABLE_KEYWORDS, this.setEditableKeyword);
        EventBus.on(Events.SET_KEYWORDS_TO_MOVE, this.setKeywordsToMove);
    },
    beforeDestroy() {
        EventBus.off(Events.SET_EDITABLE_KEYWORDS, this.setEditableKeyword);
        EventBus.off(Events.SET_KEYWORDS_TO_MOVE, this.setKeywordsToMove);
    },
    methods: {
        ...mapActions(['setShownChart', 'setIsEditPopupVisible', 'fetchProjectKeywordsInTopPositionsChartData']),
        /**
         * If a single category is being edited, then all its data is transferred to the popup
         * Else if a multiple category is being edited, then empty fields (except parent category name)
         * is transferred to the popup
         */
        setEditableKeyword(params) {
            const { keywordAuth, multiple, isAll = false } = params;
            let originalUrls = '';
            let languages = '';
            let regions = '';
            let urls = '';
            let near = '';
            let gmb = '';

            const processedKeywords = processKeywords(
                this.$store.getters,
                this.getSelectedKeywordsWithAllData,
            );

            if (multiple) {
                originalUrls = _.uniq(
                    processedKeywords.map(kw => kw.originalUrl),
                );
                languages = _.uniq(processedKeywords.map(kw => kw.language));
                regions = _.uniq(processedKeywords.map(kw => kw.region));
                urls = _.uniq(processedKeywords.map(kw => kw.fullUrl));
                near = _.uniq(processedKeywords.map(kw => kw.near));
                gmb = _.uniq(processedKeywords.map(kw => kw.gmb));
            }

            const editableKeyword = processedKeywords.find(
                el => el.id === keywordAuth,
            );
            const emptyKeyword = {
                originalUrl: originalUrls.length === 1 ? originalUrls[0] : '',
                category: editableKeyword ? editableKeyword.category : '',
                language: languages.length === 1 ? languages[0] : null,
                ignore_local: processedKeywords.every(d => d.ignore_local),
                included_in_sov: true,
                region: regions.length === 1 ? regions[0] : '',
                near: near.length === 1 ? near[0] : '',
                url: urls.length === 1 ? urls[0] : '',
                gmb: gmb.length === 1 ? gmb[0] : '',
            };

            this.editKeywordsPopup = {
                isVisible: true,
                multiple,
                isAll,
                editableKeyword:
                    multiple || isAll ? emptyKeyword : editableKeyword,
            };
            this.setIsEditPopupVisible(true);
        },
        setKeywordsToMove() {
            this.$modal.show(
                MoveKeywordsModal,
                { keywords: this.getSelectedKeywordsWithAllData },
                {
                    height: 211,
                    pivotX: 0,
                    name: 'MoveKeywords tags-mobal-window',
                    width: document.body.offsetWidth < 649 ? '98%' : 649,
                    classes: 'v--modal',
                },
            );
        },
        unsetEditableKeyword(e) {
            if (e && e.target && e.target.id === 'multi-edit-button') {
                return;
            }
            this.editKeywordsPopup = {
                isVisible: false,
                editableKeyword: null,
                multiple: false,
            };
            this.setIsEditPopupVisible(false);
        },
        getTabHeader(tabTile, cyAttr) {
            return (
                `<span data-cy="${cyAttr}">${tabTile}</span>` +
                (cyAttr === 'keyword-suggestions-tab'
                    ? '<span data-important-tooltip="' +
                      this.$t('i-keyword-suggestions') +
                      '" class="kw kw-information kw-information-suggestions"></span>'
                    : '')
            );
        },
        fetchChartData(period = null){
            const tagId = this.$route.query?.tag;
            const metricsValue = period || this.getDisplaySetting('metrics');
            const dateKey = Object.entries(exactDatePickerList).find(([key, val]) => val.altLabel === metricsValue)?.[0];
            let startDate = null;
            let endDate = null;

            if(dateKey){
                startDate = exactDatePickerList[dateKey].value;
                endDate = switchDateFormat(new Date(), 'Y-m-d');
            }

            this.fetchProjectKeywordsInTopPositionsChartData({ 
                startDate,
                endDate,
                projectId: this.getCurrentProject.project_id,
                tagId 
            }).then(()=>{
                this.initChart(this.getProjectKeywordsInTopPositions)
            });
        },
        initChart(project){
            if (!project.data) return;

            this.projectData = {
                charts: project.data,
                id: this.getCurrentProject.id,
                categories: project.categories
            };
        },
        setChart(value) {
            this.chart = value;
        },
        onDateChange(value){
            this.fetchChartData(value);
        },
        debouncedHandler: _.debounce(fn => fn(), 50),
        resizeChart() {
            if (this.chart) {
                window.dispatchEvent(new Event('resize'));
            }
        },
    },
};
</script>

<style lang="scss">
@import '~sass/partials/new-nav-tabs';

.chart-and-metrics-wrapper {
    width: 100%;
    display: flex;
    border: 1px solid kw-color(kw-light-grey, 1);
    border-radius: 4px;

    .project-overview__metrics-container {
        padding-left: 12px;
        padding-top: 16px;
        padding-bottom: 12px;
    }

    .overview-chart-container {
        flex: 1;
        width: calc(100% - 502px);
        border-left: 1px solid kw-color(kw-light-grey, 1);
        padding: 16px 8px 8px;
        .project-overview__chart-header {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #63697b;
            padding: 0 20px;
            width: 100%;
        }
        .overview-chart-wrapper {
            height: 270px;
        }
    }
}

.content-and-filter-wrap {
    position: relative;

    .project-overview {
        width: 100%;

        &__metrics-container {
            .dashbord-metrics {
                flex-direction: row;
            }

            .metrics-btn {
                height: 224px;
                width: 40px;
                justify-content: inherit;
                .btn-group {
                    padding: 0 0 0 1px;
                }
                .btn-default {
                    font-size: 13px;
                    padding: 20px 3px;
                    text-align: center;
                    width: 100%;
                    color: #63697b !important;
                    &:active,
                    &.active {
                        font-weight: 500;
                        color: #63697b !important;
                        background-color: #f2fafb !important;
                    }

                    &:hover {
                        color: #63697b !important;
                    }
                }
                .btn-default:hover {
                    background-color: #f2fafb !important;
                }
            }
        }
    }
}

.dashboard-table-wrap {
    .nav-tabs {
        @extend .nav-tabs_outlined;
        margin: 0;
        background: white;
        display: flex;
        overflow-y: hidden;
        overflow-x: auto;
    }

    .tab__share-of-voice {
        .tab-content {
            margin: 7px 0 15px 0;
        }
    }

    .tab__keyword-suggestions {
        margin-top: 19px;
    }

    .tab-content {
        margin-bottom: 15px;
        margin: 0;
    }
}

.tab-content {
    .keywords-container_share-view {
        margin-top: 20px;
    }
}

@media screen and (min-width: 960px) {
    .dashboard-table-wrap {
        flex-grow: 1;
        min-width: 650px;
    }
}

@media (max-width: 959px) {
    .dashboard-table-wrap {
        width: 100%;

        .nav-tabs {
            margin: 0;
        }
    }
}
</style>
